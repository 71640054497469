<template>
    <!--  Content Start -->
    <div class="sabc_audio_outer">
        <div class="container">
           <div class="row">
              <h1>Radio Stations</h1>
              <p>Tap station to play</p>
              <span v-for="channel in channelList">
                 <router-link :to="{ name: 'radio-live-stream', params: { channel: channel.name.toLowerCase().trim()}}">
                    <img alt="audio logo 1" :src="channel.src" style="padding: 10px 10px;">
                  </router-link>
               </span>
           </div>
        </div>
     </div>
    <!--  Content end -->
</template>

<script>

    export default {
        name: 'radio',
        data() {
            return {
               channelList:[
                  {src:'/public/images/audio_logo1.webp', name:'METROFM'},
                  {src:'/public/images/audio_logo2.webp', name:'LIGWALAGWALA'},
                  {src:'/public/images/audio_logo3.webp', name:'UMHLOBOWENENE'},
                  {src:'/public/images/audio_logo4.webp', name:'MUNGANALONENE'},
                  {src:'/public/images/audio_logo5.webp', name:'PHALAPHALA'},
                  {src:'/public/images/audio_logo6.webp', name:'RADIO2000'},
                  {src:'/public/images/audio_logo7.webp', name:'THOBELA'},
                  {src:'/public/images/audio_logo8.webp', name:'UKHOZIFM'},
                  {src:'/public/images/audio_logo9.webp', name:'TRUFM'},
                  {src:'/public/images/audio_logo10.webp', name:'LESEDI'},
                  // {src:'/public/images/audio_logo11.webp', name:'LIGWALAGWALA'},
                  {src:'/public/images/audio_logo12.webp', name:'SAFM'},
                  {src:'/public/images/audio_logo13.webp', name:'MOTSWEDING'},
                //   {src:'/public/images/audio_logo6.webp', name:'RADIO2000XTRA'},
               ]
            }
        },
        components: {},
        mounted() {
        },
       watch: {
       },
        serverPrefetch() {
        },
        computed: {
        },
        metaInfo() {
           if (this.$store.state.siteSetting) {
                let ss = this.$store.state.siteSetting;

                let title = this.$getSafe(() => ss.radio_meta_title);
                let description = this.$getSafe(() => ss.radio_meta_description);
                let keywords = this.$getSafe(() => ss.radio_meta_keywords);

                return {
                    title: title,
                    description: description,
                    meta: this.$getMetaTags(title, description, keywords),
                    link: [
                        {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}
                    ],
                }
            }

        },

        methods: {
        }
    }

</script>
<style lang="scss">
@import '~@/assets/scss/audio.scss';
</style>
